<template>
  <div :id="`${attributeName}-attribute`">
    <v-data-table
      :headers="header"
      :items="attributeData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 20]
      }"
      class="elevation-1 mb-5"
      hide-default-header
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template v-slot:[`item.colorImage`]="{ item }">
        <div
          class="color-box"
          :style="`background: ${item.colorImage}`" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeColor(item)" />
      </template>
    </v-data-table>
    <v-row
      no-gutters
      justify="center"
      class="mb-5">
      <v-btn
        color="primary"
        @click="addSubColor()">
        Add Sub Color
      </v-btn>
    </v-row>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  props: {
    attributeName: {
      type: String,
      requied: true,
      default: ''
    },
    parentId: {
      type: Number,
      requied: true,
      default: 0
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'ID',
          value: 'id',
          width: 50
        },
        {
          text: 'Color/Image',
          value: 'colorImage',
          width: 75,
          align: 'center'
        },
        {
          text: 'Name',
          value: 'name',
          width: 150
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      attributeData: [],
      isEdited: false,
      formData: [{}],
      textFieldRules: {
        required: true
      },
      getting: false,
      modalLoading: false,
      isFocus: false
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  watch: {
    parentId (val) {
      if (val !== -1) {
        this.getAttribute(this.options)
      }
    }
  },
  created () {
    this.getAttribute(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    addSubColor () {
      this.$emit('addSubColor', {
        isChild: true,
        parentId: this.parentId
      })
    },
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },
    async getAttribute (options) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await ProductAttributeService.getProductAttribute(`${this.attributeName}/${this.parentId}/childs`, {
            ...options
          })
          this.itemsLength = data.total
          this.pageCount = data.pages
          this.attributeData = data.results.map((r) => ({
            ...r,
            colorImage: getImageOrGradientCss(r)
          }))
        } catch (error) {
          console.error('getAttribute: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
          this.$emit('getData', this.attributeData)
        }
      }
    },
    optionsUpdated () {
      this.getAttribute(this.options)
    },
    editItem (item) {
      this.$emit('editItem', item)
    },
    async createAttribute (items) {
      const { data } = await ProductAttributeService.createProductAttribute(this.attributeName, {
        name: items[0].name
      })
      return !!data.id
    },

    async removeColor (item) {
      this.$emit('removeColor', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-box {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
}
div#colors-attribute {
  padding: 15px 0;
}
</style>
