<template>
  <div id="color-attribute">
    <v-data-table
      id="color-attribute-table"
      :headers="header"
      :items="attributeData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :expanded.sync="expanded"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      single-expand
      show-expand
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()"
      @item-expanded="setExpanded($event)">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              {{ attributeName }}
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <search-box
            v-model="search"
            @on-search="searchAttribute($event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-1 ml-2"
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.name"
                          :rules="textFieldRules"
                          label="Name"
                          :readonly="modalLoading" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-tabs v-model="tab">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab
                          v-for="(item, index) in items"
                          :key="`item-${index}`">
                          {{ item }}
                        </v-tab>
                      </v-tabs>
                      <v-tabs-items
                        v-model="tab"
                        class="tab-item">
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div
                                v-for="(num, index) in formData.code"
                                :key="`ColorPickerNumber-${index}`"
                                class="color-picker">
                                <v-color-picker
                                  v-model="formData.code[index]"
                                  class="mx-auto my-5"
                                  elevation="1"
                                  dot-size="10"
                                  :disabled="modalLoading"
                                  mode="hexa" />
                                <gw-icon-hover
                                  v-if="index !== 0"
                                  class="color-picker-bin"
                                  icon-name="mdi-delete"
                                  icon-hover="mdi-delete-empty"
                                  :disabled="modalLoading"
                                  absolute
                                  small
                                  @click="removeColorPicker(index)" />
                              </div>
                              <div class="add-color-picker">
                                <v-btn
                                  class="my-3"
                                  color="primary"
                                  @click="addColorPicker()">
                                  add
                                </v-btn>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <gw-drop-file
                                v-model="formData.imageUrl"
                                :validate-func="validateImageFile"
                                label="Color Image"
                                s3-path="/color-images"
                                validate />
                              <p class="text-caption mb-0">
                                * Image aspect ratio is 1:1
                              </p>
                              <p class="text-caption mb-0">
                                * Min size is 50x50 pixel
                              </p>
                              <p class="text-caption mb-0">
                                * Max size is 100x100 pixel
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template
        #[`item.bcSync`]="{ item }"
        class="d-flex justify-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
              v-bind="attrs"
              color="success"
              v-on="on">
              mdi-check-circle-outline
            </v-icon>
            <v-icon
              v-else
              v-bind="attrs"
              color="error"
              v-on="on">
              mdi-sync-alert
            </v-icon>
          </template>
          <span v-if="item.bcSync && item.bcSync.updatedAt">
            Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
          </span>
          <span v-else>
            Not sync yet
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.colorImage`]="{ item }">
        <div
          class="color-box"
          :style="`background: ${item.colorImage}`">
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <table-sub-color
            v-if="item.id"
            attribute-name="colors"
            :parent-id="item.id"
            @getData="getChildData($event)"
            @editItem="editItem($event)"
            @removeColor="removeAttribute($event)"
            @addSubColor="addSubColor($event)" />
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="item.id === expandedData.parentId">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <gw-icon-hover
            class="color-table-bin"
            icon-name="mdi-delete"
            icon-hover="mdi-delete-empty"
            :disabled="isBtnDisable"
            small
            @click="removeAttribute(item)" />
        </div>
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TableSubColor from '@/views/attributes/components/TableSubColor.vue'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import SearchBox from '@/components/SearchBox.vue'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  components: {
    tableSubColor: TableSubColor,
    searchBox: SearchBox
  },
  data () {
    return {
      tab: null,
      items: [
        'Color',
        'Image'
      ],
      expanded: [],
      attributeName: 'colors',
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false]
      },
      header: [
        {
          text: 'Sync',
          value: 'bcSync',
          width: 80
        },
        {
          text: 'ID',
          value: 'id',
          width: 50
        },
        {
          text: 'Color/Image',
          value: 'colorImage',
          width: 75,
          align: 'center'
        },
        {
          text: 'Name',
          value: 'name',
          width: 150
        },
        {
          text: '',
          value: 'data-table-expand',
          width: 25
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
          align: 'end'
        }
      ],
      attributeData: [],
      isEdited: false,
      formData: {
        code: ['#ffffffff'],
        imageUrl: ''
      },
      textFieldRules: {
        required: true
      },
      getting: false,
      modalLoading: false,
      isFocus: false,
      expandedData: {},
      isBtnDisable: true,
      search: ''
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  mounted () {
    if (this.search === '') {
      this.search = this.$route.query?.search || ''
    }
    this.getAttribute(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    removeColorPicker (index) {
      this.formData.code.splice(index, 1)
    },
    addColorPicker () {
      this.formData.code.push('#ffffffff')
    },
    setExpanded (e) {
      this.isBtnDisable = true
      if (e.value && e.item && e.item.id) {
        this.expandedData.parentId = e.item.id
      } else {
        this.expandedData = {}
      }
    },
    getChildData (e) {
      if (this.expandedData.parentId) {
        this.expandedData.data = e || []
        this.expandedData.length = e.length
        if (e.length > 0) {
          this.isBtnDisable = true
        } else {
          this.isBtnDisable = false
        }
      }
    },
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },
    searchAttribute (value) {
      this.options.page = 1
      this.search = value

      this.$router.push({
        name: this.$route.name,
        query: { search: value }
      }).catch(() => {})
      this.getAttribute(this.options)
    },
    async getAttribute (options) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await ProductAttributeService.getProductAttribute(this.attributeName, {
            ...options,
            isChild: true,
            search: this.search
          })
          this.itemsLength = data.total
          this.pageCount = data.pages
          this.attributeData = data.results.map((r) => ({
            ...r,
            colorImage: getImageOrGradientCss(r)
          }))
        } catch (error) {
          console.error('getAttribute: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
          this.expanded = []
          this.expandedData = {}
        }
      }
    },
    optionsUpdated () {
      this.getAttribute(this.options)
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {
        code: ['#ffffffff'],
        imageUrl: ''
      }
      this.tab = 0
    },
    addSubColor (item) {
      this.formData = {
        code: ['#ffffffff'],
        imageUrl: '',
        isChild: item.isChild,
        parentId: item.parentId
      }
      this.dialog = true
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        name: item.name,
        code: item.code,
        imageUrl: item.imageUrl,
        isChild: item.isChild,
        parentId: item.parentId
      }
      if (item.imageUrl) {
        this.tab = 1
      }
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()
      if (vaid) {
        let isSuscess = false
        let message = 'created'
        try {
          if (this.isEdited) {
            isSuscess = await this.updateAttribute(this.formData)
            message = 'updated'
          } else {
            isSuscess = await this.createAttribute(this.formData)
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code} : ${error.message}`,
            type: 'error'
          })
          console.error('saveAttribute', error)
        } finally {
          this.closeFormModal()
          this.expanded = []
          this.expandedData = {}
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.${this.capitalize(this.attributeName)} ${message}`),
              type: 'success'
            })
            this.getAttribute(this.options)
          }
        }
      }
      this.modalLoading = false
    },
    async createAttribute (item) {
      const { data } = await ProductAttributeService.createProductAttribute(this.attributeName, {
        ...item,
        name: item.name.trim(),
        isChild: item.isChild || false
      })
      return !!data.id
    },
    async updateAttribute (item) {
      const { data } = await ProductAttributeService.updateProductAttribute({
        path: this.attributeName,
        id: item.id
      }, {
        ...item,
        name: item.name.trim()
      })
      return !!data.id
    },
    async removeAttribute (item) {
      this.setModal({
        value: true,
        title: `Remove${this.capitalize(this.attributeName)}`,
        message: `Do you want to remove the ${this.attributeName}`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveAttribute(item.id)
      })
    },
    async confirmRemoveAttribute (id) {
      try {
        const { data } = await ProductAttributeService.deleteAttribute(this.attributeName, id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t(`snackbar.${this.capitalize(this.attributeName)} removed`),
            type: 'success'
          })
          if (this.options.page > 1 && this.attributeData.length < 2) {
            this.options.page--
          } else {
            this.getAttribute(this.options)
          }
        } else {
          const error = {
            code: 400,
            message: `${this.capitalize(this.attributeName)} ID not found or status not change`
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveAttribute', error)
      }
    },
    async validateImageFile (event) {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = (window || window.webkitURL).URL.createObjectURL(event)
        img.onload = () => {
          const ratio1By1 = img.naturalWidth === img.naturalHeight
          const widthLessThan150 = img.naturalWidth >= 50 && img.naturalWidth <= 100
          const heightLessThan150 = img.naturalHeight >= 50 && img.naturalHeight <= 100
          const valid = ratio1By1 && widthLessThan150 && heightLessThan150
          if (!valid) {
            this.setModal({
              value: true,
              title: 'Error',
              message: 'Max size is 100x100 pixel and Image ratio is 1:1',
              confirmText: 'Close',
              confirmOnly: true,
              confirmType: 'error'
            })
          }
          resolve(valid)
        }
      })
    }
  }
}
</script>

<style lang="scss">
#color-attribute-table {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #646464;
    border-radius: 25px;
  }
}
</style>

<style lang="scss" scoped>
.color-box {
  background-size: cover;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
}
.add-color-picker {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
button.color-picker-bin {
  top: 5px;
  right: 10px;
}
.color-picker {
  position: relative;
}
.tab-item {
  width: 100%;
}
</style>
